// Angular
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from '../environments/environment';

// Moment
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

// Charts
import { NgChartsModule } from 'ng2-charts';

import { EditorModule } from '@tinymce/tinymce-angular';

// Material components
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DayViewComponent } from './components/day-view/day-view.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table'
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// Third Party Modules
import { NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter, NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS} from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import LogRocket from 'logrocket';

// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import momentPlugin from '@fullcalendar/moment'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'

FullCalendarModule.registerPlugins([
  momentPlugin,
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
  resourceTimeGridPlugin
])

// Custom components
import { MenuComponent } from './components/menu/menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DayViewFrmFilterComponent } from './components/day-view/day-view-frm-filter/day-view-frm-filter.component';
import { ServicesComponent } from './components/forms/services/services.component';
import { SchedulesComponent } from './components/forms/schedules/schedules.component';
import { StudentAccountComponent } from './components/forms/student-account/student-account.component';
import { LessonComponent } from './components/forms/lesson/lesson.component';
import { AppointmentDetailsComponent } from './components/forms/appointment-details/appointment-details.component';
import { InquiryComponent } from './components/forms/inquiry/inquiry.component';
import { GroupLessonComponent } from './components/forms/group-lesson/group-lesson.component';
import { LessonTypeDropdownComponent } from './components/forms/utility/lesson-type-dropdown/lesson-type-dropdown.component';
import { InstructorListDropdownComponent } from './components/forms/utility/instructor-list-dropdown/instructor-list-dropdown.component';
import { DurationDropdownComponent } from './components/forms/utility/duration-dropdown/duration-dropdown.component';
import { AutocompleteComponent } from './components/forms/utility/autocomplete/autocomplete.component';
import { PersonalInformationComponent } from './components/forms/utility/personal-information/personal-information.component';
import { StandingAppointmentComponent } from './components/forms/utility/standing-appointment/standing-appointment.component';
import { UtilityService } from './services/utility.service';
import { RepetitionUnitsComponent } from './components/forms/utility/repetition-units/repetition-units.component';
import { StudentsListComponent } from './components/student/students-list/students-list.component';
import { StudentDashboardComponent } from './components/student/student-dashboard/student-dashboard.component';
import { GenericTableComponent } from './components/generic/generic-table/generic-table.component';
import { LessonEnrollmentsComponent } from './components/student/student-dashboard/tables/lesson-enrollments/lesson-enrollments.component';
import { ServiceEnrollmentsComponent } from './components/student/student-dashboard/tables/service-enrollments/service-enrollments.component';
import { PaymentReportsComponent } from './components/student/student-dashboard/tables/payment-reports/payment-reports.component';
import { LessonListComponent } from './components/student/student-dashboard/tables/lesson-list/lesson-list.component';
import { ServiceListComponent } from './components/student/student-dashboard/tables/service-list/service-list.component';
import { InquiryViewComponent } from './components/reports/inquiry-view/inquiry-view.component';
import { PhonePipe } from './pipes/phone.pipe';
import { SetOpeningBalanceComponent } from './components/forms/set-opening-balance/set-opening-balance.component';
import { OpeningBalanceComponent } from './components/forms/opening-balance/opening-balance.component';
import { StudentDepartmentComponent } from './components/forms/student-department/student-department.component';
import { ProjectionsReportComponent } from './components/reports/projections-report/projections-report.component';
import { CanceledEventsComponent } from './components/reports/canceled-events/canceled-events.component';
import { ViewTableComponent } from './components/base/view-table/view-table.component';
import { PackageListComponent } from './components/reports/package-list/package-list.component';
import { EnrollmentsViewComponent } from './components/reports/enrollments-view/enrollments-view.component';
import { PaymentsReportViewComponent } from './components/reports/payments-report-view/payments-report-view.component';
import { BaseComponent } from './components/forms/base-form/base-form.component';
import { PackageEntityComponent } from './components/forms/package-entity/package-entity.component';
import { ChatSuccessRateComponent } from './components/reports/chat-success-rate/chat-success-rate.component';
import { StudentsInventoryComponent } from './components/reports/students-inventory/students-inventory.component';
import { LessonsListReportComponent } from './components/reports/lessons-list-report/lessons-list-report.component';
import { LessonTypeListDropdownComponent } from './components/forms/utility/lesson-type-list-dropdown/lesson-type-list-dropdown.component';
import { DepartmentTypeDropdownComponent } from './components/forms/utility/department-type-dropdown/department-type-dropdown.component';
import { FilterResultsPanelComponent } from './components/utility/filter-results-panel/filter-results-panel.component';
import { AmiReportsComponent } from './components/reports/ami-reports/ami-reports.component';
import { AmiStaffPerformanceComponent } from './components/reports/ami-staff-performance/ami-staff-performance.component';
import { StudioBusinessSummaryComponent, HtmlTooltipComponent, HtmlTooltipDirective } from './components/reports/studio-business-summary/studio-business-summary.component';
import { RoyaltyReportComponent } from './components/reports/royalty-report/royalty-report.component';
import { NgInitDataService } from './services/ng-init-data.service';
import { ErrorMessageComponent } from './components/utility/error-message/error-message.component';
import { EnrollmentEntityComponent } from './components/forms/enrollment-entity/enrollment-entity.component';
import { PaymentsEntityComponent } from './components/forms/payments-entity/payments-entity.component';
import { EventLessonEntityComponent } from './components/forms/event-lesson-entity/event-lesson-entity.component';
import { EventGroupLessonEntityComponent } from './components/forms/event-group-lesson-entity/event-group-lesson-entity.component';
import { EventSchedulesEntityComponent } from './components/forms/event-schedules-entity/event-schedules-entity.component';
import { EventServicesEntityComponent } from './components/forms/event-services-entity/event-services-entity.component';
import { EntityMatDialogActionsComponent } from './components/utility/entity/entity-mat-dialog-actions/entity-mat-dialog-actions.component';
import { EntityConfirmDeleteComponent } from './components/utility/entity/entity-confirm-delete/entity-confirm-delete.component';
import { EntityMatTabViewComponent } from './components/utility/entity/entity-mat-tab-view/entity-mat-tab-view.component';
import { EntityMatDialogTitleComponent } from './components/utility/entity/entity-mat-dialog-title/entity-mat-dialog-title.component';
import { StudentAccountEntityComponent } from './components/forms/student-account-entity/student-account-entity.component';
import { BaseDialogComponent } from './components/forms/base-dialog/base-dialog.component';
import { PageTitleComponent } from './components/utility/page-title/page-title.component';
import { StudentInquiryEntityComponent } from './components/forms/student-inquiry-entity/student-inquiry-entity.component';
import { LessonsBookedChartComponent } from './components/charts/lessons-booked-chart/lessons-booked-chart.component';
import { PaymentsChartComponent } from './components/charts/payments-chart/payments-chart.component';
import { EnrollmentsChartComponent } from './components/charts/enrollments-chart/enrollments-chart.component';
import { UpdateLessonComponent } from './components/forms/update-lesson/update-lesson.component';
import { UpdateGroupLessonComponent } from './components/forms/update-group-lesson/update-group-lesson.component';
import { UpdateServiceComponent } from './components/forms/update-service/update-service.component';
import { CancelRescheduleComponent } from './components/forms/cancel-reschedule/cancel-reschedule.component';
import { BaseSimpleDialogComponent } from './components/forms/base-simple-dialog/base-simple-dialog.component';
import { PostLessonComponent } from './components/forms/post-lesson/post-lesson.component';
import { UpdateStatusComponent } from './components/forms/update-status/update-status.component';
import { SuccessMessageComponent } from './components/utility/success-message/success-message.component';
import { DisplayAttendeesComponent } from './components/utility/entity/display-attendees/display-attendees.component';
import { WeekViewComponent } from './components/appointments/week-view/week-view.component';
import { StudentRecordEntityComponent } from './components/forms/student-record-entity/student-record-entity.component';
import { SendUpdateAPIComponent } from './components/forms/send-update-api/send-update-api.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { LoginComponent } from './authenticate/login/login.component';
import { ForgotPasswordComponent } from './authenticate/forgot-password/forgot-password.component';
import { LogoutComponent } from './authenticate/logout/logout.component';
import { CashReportComponent } from './components/reports/cash-report/cash-report.component';
import { PaymentsDueComponent } from './components/reports/payments-due/payments-due.component';
import { PaymentsPastDueComponent } from './components/reports/payments-past-due/payments-past-due.component';
import { PaymentsMadeEncompassingComponent } from './components/reports/payments-made-encompassing/payments-made-encompassing.component';
import { PaymentsMadeLessonComponent } from './components/reports/payments-made-lesson/payments-made-lesson.component';
import { PaymentsMadeMiscComponent } from './components/reports/payments-made-misc/payments-made-misc.component';
import { PaymentsMadeSundryComponent } from './components/reports/payments-made-sundry/payments-made-sundry.component';
import { EnrollmentTrackingComponent } from './components/reports/enrollment-tracking/enrollment-tracking.component';
import { InquiriesComponent } from './components/reports/inquiries/inquiries.component';
import { CancellationsComponent } from './components/reports/cancellations/cancellations.component';
import { ServiceSalesComponent } from './components/reports/service-sales/service-sales.component';
import { CounselorsComponent } from './components/reports/counselors/counselors.component';
import { SupervisorsComponent } from './components/reports/supervisors/supervisors.component';
import { AppointmentSheetComponent } from './components/reports/appointment-sheet/appointment-sheet.component';
import { DisplayInstructorsComponent } from './components/utility/display-instructors/display-instructors.component';
import { PackageListRetiredComponent } from './components/reports/package-list-retired/package-list-retired.component';
import { EnrollmentDropComponent } from './components/forms/enrollment-drop/enrollment-drop.component';
import { CancelFutureAppointmentComponent } from './components/forms/cancel-future-appointment/cancel-future-appointment.component';
import { UpdateStatusGroupLessonComponent } from './components/forms/update-status-group-lesson/update-status-group-lesson.component';
import { EnrollmentLessonListViewComponent } from './components/reports/enrollment-lesson-list-view/enrollment-lesson-list-view.component';
import { AmiMiscReportComponent } from './components/reports/ami-misc-report/ami-misc-report.component';
import { MiscStudentReportComponent } from './components/student/student-dashboard/tables/misc-student-report/misc-student-report.component';
import { SundryStudentReportComponent } from './components/student/student-dashboard/tables/sundry-student-report/sundry-student-report.component';
import { LessonStudentReportComponent } from './components/student/student-dashboard/tables/lesson-student-report/lesson-student-report.component';
import { StudentFutureLessonsComponent } from './components/student/student-dashboard/tables/student-future-lessons/student-future-lessons.component';
import { RouteEventsService } from './route-events.service';
import { NonUnitStudentReportComponent } from './components/student/student-dashboard/tables/non-unit-student-report/non-unit-student-report.component';
import { NonUnitLessonsComponent } from './components/student/student-dashboard/tables/non-unit-lessons/non-unit-lessons.component';
import { StudentInquiryDetailsComponent } from './components/forms/student-inquiry-details/student-inquiry-details.component';
import { ReportNavMenuComponent } from './components/reports/report-nav-menu/report-nav-menu.component';
import { EnrollmentViewLessonComponent } from './components/forms/enrollment-view-lesson/enrollment-view-lesson.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MiscServiceDeductionsEntityComponent } from './components/forms/misc-service-deductions-entity/misc-service-deductions-entity.component';
import { CreditCardReadInputComponent } from './components/forms/credit-card-read-input/credit-card-read-input.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { PaidLessonAuditComponent } from './components/audit/paid-lesson-audit/paid-lesson-audit.component';
import { WarningMessageComponent } from './components/utility/warning-message/warning-message.component';
import { SplashComponent } from './components/splash/splash.component';
import { SupportRequestComponent } from './components/forms/support-request/support-request.component';
import { StudentDashboardWrapperComponent } from './components/student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { ConfigurationDashboardMenuComponent } from './components/configuration/configuration-dashboard-menu/configuration-dashboard-menu.component';
import { CalendarComponent } from './components/configuration/calendar/calendar.component';
import { WelcomeComponent } from './components/configuration/welcome/welcome.component';
import { CashReportIndividualComponent } from './components/reports/cash-report/cash-report-individual/cash-report-individual.component';
import { PaymentFormDialogWrapperComponent } from './components/wrapper/payment-form-dialog-wrapper/payment-form-dialog-wrapper.component';
import { SignatureEnrollmentComponent } from './components/enrollment/signature-enrollment/signature-enrollment.component';
import { BaseEnrollmentComponent } from './components/enrollment/base-enrollment/base-enrollment.component';
import { EnrollmentPaymentDisplayComponent } from './components/forms/enrollment-payment-display/enrollment-payment-display.component';
import { AuthRESTService } from './services/auth-rest.service';
import { AuthService } from './services/auth.service';
import { OrphanedLessonReportComponent } from './components/reports/orphaned-lesson-report/orphaned-lesson-report.component';
import { UserManagementComponent } from './components/configuration/user-management/user-management.component';
import { CustomImportComponent } from './components/configuration/import/custom-import/custom-import.component';
import { FloatingrainImportComponent } from './components/configuration/import/floatingrain-import/floatingrain-import.component';
import { IdanceImportComponent } from './components/configuration/import/idance-import/idance-import.component';
import { StaffManagementComponent } from './components/configuration/staff-management/staff-management.component';
import { FrmManageStaffComponent } from './components/forms/frm-manage-staff/frm-manage-staff.component';
import { SimpleImportComponent } from './components/configuration/import/simple-import/simple-import.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ComponentRefreshService } from './services/component-refresh.service';

const CUSTOM_MOMENT_FORMAT: NgxMatDateFormats = {
  parse: {
    // dateInput: 'YYYY-MM-DD\THH:MM:SS',
    dateInput: 'l, LT',
  },
  display: {
    dateInput: 'dddd, l, LT',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
}

const CUSTOM_MOMENT_FORMAT_ALT = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

import { LicenseAgreementDialogComponent } from './components/utility/license-agreement-dialog/license-agreement-dialog.component';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { CancelFutureServiceComponent } from './components/forms/cancel-future-service/cancel-future-service.component';
import { SimpleImportInstructorsComponent } from './components/configuration/import/simple-import/simple-import-instructors/simple-import-instructors.component';
import { SimpleImportPackagesComponent } from './components/configuration/import/simple-import/simple-import-packages/simple-import-packages.component';
import { SimpleImportStudentAccountsComponent } from './components/configuration/import/simple-import/simple-import-student-accounts/simple-import-student-accounts.component';
import { SimpleImportEnrollmentComponent } from './components/configuration/import/simple-import/simple-import-enrollment/simple-import-enrollment.component';
import { SimpleImportPaymentsComponent } from './components/configuration/import/simple-import/simple-import-payments/simple-import-payments.component';
import { SimpleImportLessonsComponent } from './components/configuration/import/simple-import/simple-import-lessons/simple-import-lessons.component';
import { ConfirmDialogComponent } from './components/utility/confirm-dialog/confirm-dialog.component';
import { SessionStartTimesComponent } from './components/configuration/session-start-times/session-start-times.component';
import { StudioInformationComponent } from './components/configuration/studio-information/studio-information.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ViewPaymentsFromEnrollmentComponent } from './components/forms/view-payments-from-enrollment/view-payments-from-enrollment.component';
import { EnrollmentViewPaymentViewComponent } from './components/reports/enrollment-view-payment-view/enrollment-view-payment-view.component';
import { AmiReportingComponent } from './components/configuration/ami-reporting/ami-reporting.component';
import { FormStudioInformationComponent } from './components/configuration/form-studio-information/form-studio-information.component';
import { FormStripeSettingsComponent } from './components/configuration/form-stripe-settings/form-stripe-settings.component';
import { IntegrationStripeComponent } from './components/configuration/integration-stripe/integration-stripe.component';
import { EmailConfigurationSettingsComponent } from './components/configuration/email-configuration-settings/email-configuration-settings.component';
import { FormStudioWorkingHoursComponent } from './components/configuration/form-studio-working-hours/form-studio-working-hours.component';
import { ScheduleConflictsComponent } from './components/utility/schedule-conflicts/schedule-conflicts.component';
import { IntegrationTwilioComponent } from './components/configuration/integration-twilio/integration-twilio.component';
import { FormTwilioSettingsComponent } from './components/configuration/form-twilio-settings/form-twilio-settings.component';
import { GiftCertificatesComponent } from './components/configuration/gift-certificates/gift-certificates.component';
import { FrmManageGiftCertificateComponent } from './components/forms/frm-manage-gift-certificate/frm-manage-gift-certificate.component';
import { PhoneNumberFormatterDirective } from './phone-number-formatter.directive';
import { SMSConfigurationSettingsComponent } from './components/configuration/smsconfiguration-settings/smsconfiguration-settings.component';
import { HtmlDecodePipe } from './pipes/html-decode.pipe';
import { TaxConfigurationComponent } from './components/configuration/tax-configuration/tax-configuration.component';
import { SimpleImportInstructorWrapperComponent } from './components/configuration/import/simple-import-instructor-wrapper/simple-import-instructor-wrapper.component';
import { SimpleImportPackagesWrapperComponent } from './components/configuration/import/simple-import-packages-wrapper/simple-import-packages-wrapper.component';
import { SimpleImportStudentAccountsWrapperComponent } from './components/configuration/import/simple-import-student-accounts-wrapper/simple-import-student-accounts-wrapper.component';
import { SimpleImportHistoryWrapperComponent } from './components/configuration/import/simple-import-history-wrapper/simple-import-history-wrapper.component';
import { SimpleImportHistoryComponent } from './components/configuration/import/simple-import-history/simple-import-history.component';
import { PaymentsEntityFormComponent } from './components/forms/payments-entity-form/payments-entity-form.component';
import { UsSalesTaxReportComponent } from './components/reports/us-sales-tax-report/us-sales-tax-report.component';
import { IntegrationSquareComponent } from './components/configuration/integration-square/integration-square.component';
import { FormSquareSettingsComponent } from './components/configuration/form-square-settings/form-square-settings.component';
import { SquareWebPaymentComponent } from './components/forms/square-web-payment/square-web-payment.component';
import { StatusBadgeUpdateComponent } from './components/utility/status-badge-update/status-badge-update.component';
import { SquareOAuthCallbackComponent } from './components/square/square-oauth-callback-component/square-oauth-callback-component.component';
import { SquareConnectedDevicesComponent } from './components/square/configuration/square-connected-devices/square-connected-devices.component';
import { SquareTerminalPaymentComponent } from './components/forms/square-terminal-payment/square-terminal-payment.component';
import { CalendarColorFormComponent } from './components/configuration/calendar/calendar-color-form/calendar-color-form.component';
import { AddTerminalDeviceDialogComponent } from './components/square/add-terminal-device-dialog/add-terminal-device-dialog.component';
import { SimpleImportSpreadsheetComponent } from './components/configuration/import/simple-import-spreadsheet/simple-import-spreadsheet.component';
import { SimpleImportSpreadsheetWrapperComponent } from './components/configuration/import/simple-import-spreadsheet-wrapper/simple-import-spreadsheet-wrapper.component';
import { WeeklyEnrollmentSalesTrackingReportComponent } from './components/reports/weekly-enrollment-sales-tracking-report/weekly-enrollment-sales-tracking-report.component';
import { PayrollReportSimpleComponent } from './components/reports/payroll-report-simple/payroll-report-simple.component';
import { StaffSuccessReportComponent } from './components/reports/staff-success-report/staff-success-report.component';
import { TutorialSingleComponent } from './components/configuration/tutorial/tutorial-single/tutorial-single.component';
import { TutorialsComponent } from './components/configuration/tutorial/tutorials/tutorials.component';
import { StaffTrackingReportComponent } from './components/reports/staff-tracking-report/staff-tracking-report.component';
import { SquareTerminalSaveCardComponent } from './components/forms/square-terminal-save-card-component/square-terminal-save-card-component.component';
import { WeeklyStudentActivityComponent } from './components/reports/weekly-student-activity/weekly-student-activity.component';
import { RefreshHelperComponent } from './components/reports/refresh-helper/refresh-helper.component';
import { SaleNoSaleReportComponent } from './components/reports/sale-no-sale-report/sale-no-sale-report.component';
// registerCellType(NumericCellType);
// registerPlugin(UndoRedo);
// registerCellType(DropdownCellType);

// registerAllCellTypes();
// registerAllRenderers();
// registerAllEditors();
// registerAllValidators();
// registerAllPlugins();
// registerAllModules();

@NgModule({
  declarations: [
    AppComponent,
    LicenseAgreementDialogComponent,
    CancelFutureServiceComponent,
    CapitalizePipe,
    MenuComponent,
    DashboardComponent,
    DayViewComponent,
    DayViewFrmFilterComponent,
    LessonComponent,
    ServicesComponent,
    SchedulesComponent,
    StudentAccountComponent,
    AppointmentDetailsComponent,
    InquiryComponent,
    GroupLessonComponent,
    LessonTypeDropdownComponent,
    InstructorListDropdownComponent,
    DurationDropdownComponent,
    AutocompleteComponent,
    PersonalInformationComponent,
    StandingAppointmentComponent,
    RepetitionUnitsComponent,
    StudentsListComponent,
    GenericTableComponent,
    LessonEnrollmentsComponent,
    ServiceEnrollmentsComponent,
    PaymentReportsComponent,
    LessonListComponent,
    ServiceListComponent,
    InquiryViewComponent,
    StudentDashboardComponent,
    PhonePipe,
    SetOpeningBalanceComponent,
    OpeningBalanceComponent,
    StudentDepartmentComponent,
    ProjectionsReportComponent,
    CanceledEventsComponent,
    ViewTableComponent,
    PackageListComponent,
    EnrollmentsViewComponent,
    PaymentsReportViewComponent,
    BaseComponent,
    PackageEntityComponent,
    ChatSuccessRateComponent,
    StudentsInventoryComponent,
    LessonsListReportComponent,
    LessonTypeListDropdownComponent,
    DepartmentTypeDropdownComponent,
    FilterResultsPanelComponent,
    AmiReportsComponent,
    AmiStaffPerformanceComponent,
    StudioBusinessSummaryComponent,
    HtmlTooltipComponent,
    HtmlTooltipDirective,
    RoyaltyReportComponent,
    ErrorMessageComponent,
    EnrollmentEntityComponent,
    PaymentsEntityComponent,
    EventLessonEntityComponent,
    EventGroupLessonEntityComponent,
    EventSchedulesEntityComponent,
    EventServicesEntityComponent,
    EntityMatDialogActionsComponent,
    EntityConfirmDeleteComponent,
    EntityMatTabViewComponent,
    EntityMatDialogTitleComponent,
    StudentAccountEntityComponent,
    BaseDialogComponent,
    PageTitleComponent,
    StudentInquiryEntityComponent,
    LessonsBookedChartComponent,
    PaymentsChartComponent,
    EnrollmentsChartComponent,
    UpdateLessonComponent,
    UpdateGroupLessonComponent,
    UpdateServiceComponent,
    CancelRescheduleComponent,
    BaseSimpleDialogComponent,
    PostLessonComponent,
    UpdateStatusComponent,
    SuccessMessageComponent,
    DisplayAttendeesComponent,
    WeekViewComponent,
    StudentRecordEntityComponent,
    SendUpdateAPIComponent,
    AuthenticateComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    CashReportComponent,
    PaymentsDueComponent,
    PaymentsPastDueComponent,
    PaymentsMadeEncompassingComponent,
    PaymentsMadeLessonComponent,
    PaymentsMadeMiscComponent,
    PaymentsMadeSundryComponent,
    EnrollmentTrackingComponent,
    InquiriesComponent,
    CancellationsComponent,
    ServiceSalesComponent,
    CounselorsComponent,
    SupervisorsComponent,
    AppointmentSheetComponent,
    DisplayInstructorsComponent,
    PackageListRetiredComponent,
    EnrollmentDropComponent,
    CancelFutureAppointmentComponent,
    UpdateStatusGroupLessonComponent,
    EnrollmentLessonListViewComponent,
    AmiMiscReportComponent,
    MiscStudentReportComponent,
    SundryStudentReportComponent,
    LessonStudentReportComponent,
    StudentFutureLessonsComponent,
    NonUnitStudentReportComponent,
    NonUnitLessonsComponent,
    StudentInquiryDetailsComponent,
    ReportNavMenuComponent,
    EnrollmentViewLessonComponent,
    SafeHtmlPipe,
    MiscServiceDeductionsEntityComponent,
    CreditCardReadInputComponent,
    DateTimeFormatPipe,
    PaidLessonAuditComponent,
    WarningMessageComponent,
    SplashComponent,
    SupportRequestComponent,
    StudentDashboardWrapperComponent,
    CalendarComponent,
    WelcomeComponent,
    CashReportIndividualComponent,
    PaymentFormDialogWrapperComponent,
    SignatureEnrollmentComponent,
    BaseEnrollmentComponent,
    EnrollmentPaymentDisplayComponent,
    OrphanedLessonReportComponent,
    UserManagementComponent,
    FrmManageStaffComponent,
    SimpleImportComponent,
    ConfigurationDashboardMenuComponent,
    ConfigurationComponent,
    StaffManagementComponent,
    FloatingrainImportComponent,
    CustomImportComponent,
    IdanceImportComponent,
    SimpleImportInstructorsComponent,
    SimpleImportPackagesComponent,
    SimpleImportStudentAccountsComponent,
    SimpleImportEnrollmentComponent,
    SimpleImportPaymentsComponent,
    SimpleImportLessonsComponent,
    ConfirmDialogComponent,
    SessionStartTimesComponent,
    StudioInformationComponent,
    TruncatePipe,
    ViewPaymentsFromEnrollmentComponent,
    EnrollmentViewPaymentViewComponent,
    AmiReportingComponent,
    FormStudioInformationComponent,
    FormStripeSettingsComponent,
    IntegrationStripeComponent,
    EmailConfigurationSettingsComponent,
    FormStudioWorkingHoursComponent,
    ScheduleConflictsComponent,
    IntegrationTwilioComponent,
    FormTwilioSettingsComponent,
    GiftCertificatesComponent,
    FrmManageGiftCertificateComponent,
    PhoneNumberFormatterDirective,
    SMSConfigurationSettingsComponent,
    HtmlDecodePipe,
    TaxConfigurationComponent,
    SimpleImportInstructorWrapperComponent,
    SimpleImportPackagesWrapperComponent,
    SimpleImportStudentAccountsWrapperComponent,
    SimpleImportHistoryWrapperComponent,
    SimpleImportHistoryComponent,
    PaymentsEntityFormComponent,
    UsSalesTaxReportComponent,
    IntegrationSquareComponent,
    FormSquareSettingsComponent,
    SquareWebPaymentComponent,
    StatusBadgeUpdateComponent,
    SquareOAuthCallbackComponent,
    SquareConnectedDevicesComponent,
    SquareTerminalPaymentComponent,
    CalendarColorFormComponent,
    AddTerminalDeviceDialogComponent,
    SimpleImportSpreadsheetComponent,
    SimpleImportSpreadsheetWrapperComponent,
    WeeklyEnrollmentSalesTrackingReportComponent,
    PayrollReportSimpleComponent,
    StaffSuccessReportComponent,
    StaffTrackingReportComponent,
    TutorialsComponent,
    TutorialSingleComponent,
    SquareTerminalSaveCardComponent,
    WeeklyStudentActivityComponent,
    RefreshHelperComponent,
    SaleNoSaleReportComponent,
  ],
  imports: [
    EditorModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatGridListModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    MatMomentDateModule,
    MatTabsModule,
    MatRadioModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    FullCalendarModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    // NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    // NgxPopperjsModule,
    NgChartsModule,
    MatChipsModule,
    MatSnackBarModule,
    DragDropModule,
    NgxStarRatingModule,
    // HotTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    RouteEventsService,
    UtilityService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMAT_ALT },
    // Default options for Material Dialog
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        height: '500px',
        width: '610px',
        closeOnNavigation: true,
      }
    },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMAT },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
    {
      provide: APP_INITIALIZER,
      useFactory: resourceProviderFactory,
      deps: [NgInitDataService],
      multi: true
    },
    AuthRESTService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [AuthRESTService]
    },
    ComponentRefreshService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function resourceProviderFactory(ngInitDataService: NgInitDataService) {
  return () => ngInitDataService.init();
}

export function initApp(authRESTService: AuthRESTService): () => Promise<any> {
  return (): Promise<any> => {
    return;
    // return authRESTService.test_login().toPromise();
  };
}
